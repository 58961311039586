import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  Layout,
  SEO,
  GradientContainer,
  Table,
  TableRow,
  TableHeading,
  TableBody,
  TableCell,
} from '../components';

function RegisterPage() {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://widgets.mindbodyonline.com/javascripts/healcode.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <SEO title="Registration" />
      {/* <div className="mt-24" /> */}
      <healcode-widget
        data-type="registrations"
        data-widget-partner="object"
        data-widget-id="3d148413b247"
        data-widget-version="0"
      />
    </Layout>
  );
}

export default RegisterPage;
